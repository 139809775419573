import React from "react";
import Seo from "../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const PreisUndTerminePage = () => {
  return (
    <Layout>
      <Seo
        title="Preis und Termine"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      {/* <div className="d-none d-lg-block" style={{ position: "relative" }}>
        <Container className="g-0 position-absolute" fluid={true}>
          <Row className="g-0 justify-content-end">
            <Col xs={4} className="g-0">
              <StaticImage
                src="../images/leistungen-line-leave.png"
                alt=""
                placeholder="tracedSVG"
                layout="fullWidth"
                sizes="33.3333333333vw"
                // formats={["auto", "webp", "avif"]}
                // sizes="(max-width: 575px) calc(50vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
              />
            </Col>
          </Row>
        </Container>
      </div> */}

<div style={{ position: "relative", zIndex: -1 }}>
        <Container className="g-0 position-absolute" fluid={true}>
          <Row className="g-0 justify-content-end">
            <Col xs={4} className="g-0">
              <StaticImage
                src="../images/blumentopf-cut-header.png"
                alt=""
                placeholder="tracedSVG"
                layout="fullWidth"
                sizes="33.3333333333vw"
                // formats={["auto", "webp", "avif"]}
                // sizes="(max-width: 575px) calc(50vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-md-5 py-5">
        <Row>
          <Col lg={8}>
            <h2 className="section-title mb-4">Informationen zur Behandlung</h2>

            <h4 className="section-title">Erstbehandlung</h4>

            <p>
              In einer ausführlichen Anamnese gehen wir auf alle relevanten
              Aspekte Ihres Lebens und Ihre individuellen Bedürfnisse ein. Wir
              ermitteln gemeinsam, welche Faktoren entscheidend zu ihrem
              Unwohlsein beitragen. Bitte planen Sie für die erste Sitzung 90
              – 120 Minuten ein.</p>

              <h4 className="section-title">Folgesitzung</h4>

     <p>In der Folgesitzung gehen wir den entscheidenden Faktoren auf den Grund. Die mir zur Verfügung stehenden Methoden (ART /
              PK / MRT) und die energiemedizinischen Behandlungs- und
              Diagnosegeräte (Chiren-Biophotonen / Photonwave) erlauben mir,
              mehrere Aspekte Ihres Sein zu erfassen und zu behandeln. Der Fokus
              liegt immer sowohl auf der körperlichen, als auch auf der mentalen
              und psychischen Ebene. Bitte planen Sie für die Folgesitzung 60 - 90 Minuten ein.</p>
          </Col>
        </Row>

        
      </Container>

      <Container className="py-5">
      <Row className="g-3 g-lg-4 my-3">
          <Col md={6} lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column h-100 bg-tertiary spezial-pakete">
              <h3 className="h4">Honorar</h3>
              <p>Mein Ansatz beträgt: 150 CHF / Stunde</p>

              <p>Das Erarbeiten Ihrer individuellen Ernährungspläne etc wird nicht verrechnet.</p>
              <p>
                Ist mein Ansatz in Ihrer derzeitigen Lebensphase zu hoch, können
                wir das gerne besprechen.
              </p>

              <p>
                Im Verhinderungsfall bitte ich Sie, mich mindestens 24 Stunden
                im Voraus zu benachrichtigen, damit der reservierte Termin nicht
                verrechnet werden muss.
              </p>
            </div>
          </Col>

          <Col md={6} lg={8}>
            <div className="p-3 p-lg-4 d-flex flex-column h-100 bg-quinary spezial-pakete">
              <h3 className="h4">
              Kostenübernahme
              </h3>
              <p>
              Ich verfüge über das Qualitätslabel EMR. Meine Honorarrechnungen
              werden von den meisten Krankenkassen über die Zusatzversicherung
              (Komplementär) übernommen. 
            </p>

            <p>Es werden normalerweise 70-90% der Rechnung zurückvergütet.</p>

            <p>
              Bitte klären Sie die Kostenübernahme vor Behandlungsbeginn bei
              Ihrer Krankenkasse ab.
            </p>

            <p>Diese Methoden sind Bestandteil meiner Therapie und dürfen über die Krankenkasse abgerechnet werden.</p>

            <ul>
              <li>
                <a
                  href="https://emr.ch/methode/naturheilkundliche-praktiken-nhp-naturheilpraktiker"
                  target="_blank"
                  rel="noreferrer"
                >
                  Naturheilkundliche Praktiken (NHP)
                </a>
              </li>
              <li>
                <a
                  href="https://emr.ch/methode/diatetik-nhp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Diätetik
                </a>
              </li>
              <li>
                <a
                  href="https://emr.ch/methode/ausleitende-verfahren-nhp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ausleitende Verfahren (Auf meiner Homepage Entgiftung / Detox
                  genannt)
                </a>
              </li>
              <li>
                <a
                  href="https://emr.ch/methode/methode-hydrotherapie-nhp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hydrotherapie
                </a>
              </li>
              <li>
                <a
                  href="https://www.emr.ch/methode/phytotherapie-westliche"
                  target="_blank"
                  rel="noreferrer"
                >
                  Westliche Phytotherapie
                </a>
              </li>
            </ul>
            </div>
          </Col>
        </Row>

      </Container>

      

      <Container>
        <Row>
          <Col xs={10} md={8} lg={7} xl={6}>
            <StaticImage
              src="../images/home-sun-line-plant.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   243, 289, 321, 426, 456, 486, 536, 546, 578, 636, 642, 852, 912,
              //   1072, 1092, 1272,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw /12 * 10 - 24px), (max-width: 767px) 426px, (max-width: 991px) 456px, (max-width: 1199px) 536px, (max-width: 1399px) 546px, 636px"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PreisUndTerminePage;
